var bW = document.documentElement.clientWidth;
var bH = document.documentElement.clientHeight;
let responsive = 800;


// header scroll
const header = document.querySelector('.l-header');
const headerUpper = document.querySelector('.l-header__upper');
const headerLower = document.querySelector('.l-header__inner');
let scrollNum = 100;
if (bW > responsive){
  window.addEventListener('scroll', () => {
    const position = window.scrollY;
    if (position > scrollNum){
      header.classList.add('js-scroll-header');
      headerUpper.classList.add('js-scroll-header');
      headerLower.classList.add('js-scroll-header');
    } else if (position < scrollNum && headerUpper.classList.contains('js-scroll-header')) {
      header.classList.remove('js-scroll-header');
      headerUpper.classList.remove('js-scroll-header');
      headerLower.classList.remove('js-scroll-header');
    }
  });
} else {
  window.addEventListener('scroll', () => {
    const position = window.scrollY;
    if (position > scrollNum){
      header.classList.add('js-scroll-header-sp');
    } else if (position < scrollNum && headerUpper.classList.contains('js-scroll-header')) {
      header.classList.remove('js-scroll-header-sp');
    }
  });
};

// header height
window.addEventListener('load', () => {
  const headerHeight = header.clientHeight;
  const mainHeight = document.querySelector('.l-main');
  mainHeight.style.marginTop = headerHeight + 'px';
});

// sp header
const hamButton = document.querySelector('.c-ham__button');
const hamNav = document.querySelector('.c-nav__header');
const Body = document.querySelector('body');
const Header = document.querySelector('.l-header');
hamButton.addEventListener('click', () => {
  hamButton.classList.toggle('is-open-ham');
  hamNav.classList.toggle('is-open-ham');
  Header.classList.toggle('is-open-ham');
  Body.classList.toggle('is-open-ham');
});


// totop
const pagetopBtn = document.querySelector('#c-pageTop');
pagetopBtn.addEventListener('click', () => {
  return false;
  /*  window.scrollTo({
    top: 0,
    behavior: "smooth"
  }); */
});


// top kv swiper PC
if (bW > responsive){
  const swiper = new Swiper('.swiper-topkv-pc', {
    loop: true,
    speed: 2000,
    autoplay: {
      delay: 5000,
    },
  });
}


// top kv swiper SP
if (bW <= responsive){
  const swiper = new Swiper('.swiper-topkv-sp', {
    loop: true,
    speed: 2000,
    autoplay: {
      delay: 5000,
    },
  });
}













document.getElementById('body').classList.add('js-show-body');